body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 20px
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas,
        'Courier New', monospace;
}

.pure-flex {
    display: flex;
}

.flex {
    display: flex;
    align-items: center;
}

.jc-flex-end {
    justify-content: flex-end;
}

.jc-center {
    justify-content: center;
}

.jc-space-between {
    justify-content: space-between;
}

.ta-center {
    text-align: center;
}

.as-center {
    align-self: center;
}

.bold {
    font-weight: bold;
}

ul,
a {
    border: none !important;
}

.pagination {
    justify-content: center;
}